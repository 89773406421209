<template>
<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }" style="font-size: 20px;">网页手动采集</el-breadcrumb-item>
</el-breadcrumb>
  <!-- 搜索区域 -->
  <div class="search">
    <span>网址：</span>
    <el-input
      v-model="search"
      style="width: 340px"
      placeholder="输入采集网址搜索列表"
      @keydown="isEnter"
      clearable
    />
    <el-button size="mini" type="primary" @click="loadTable">查询</el-button>
    <el-button size="mini" type="success" @click="visible = true"
      >网站采集</el-button
    >
  </div>
  <!-- 表格区域 -->
  <el-table
    v-loading="loading"
    size="mini"
    :data="tableData"
    style="width: 99%"
    @cell-click="handleMouseEnter"
  >
    <el-table-column prop="id" label="编号" width="100" />
    <el-table-column prop="create_time" label="创建时间" width="180" />
    <el-table-column prop="title" label="标题(鼠标点击查看详细数据)" width="500" />
    <!-- <el-table-column prop="query_url" label="网址" width="600" /> -->
    <el-table-column prop="query_url" label="网址" width="500">
      <template v-slot="scope">
        <p class="url_p" @click.stop="$toUrl(scope.row.query_url)">
          {{ scope.row.query_url }}
        </p>
      </template>
    </el-table-column>
    <el-table-column prop="query_type" label="采集类型" width="100">
      <template v-slot="scope">
        {{ scope.row.query_type == 1 ? "手动" : "自动" }}
      </template>
    </el-table-column>
    <el-table-column prop="query_type" label="来源" width="500">
      <template v-slot="scope">
        {{ scope.row.from ?? '未知' }}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button
          size="mini"
          type="danger"
          @click.stop="handleDelete(scope.row.id)"
          >删除</el-button
        >
        <el-button
          size="mini"
          type="success"
          @click.stop="toEetails(scope.row.id)"
          >详情</el-button
        >
        <el-button
          size="mini"
          type="warning"
          @click.stop="againGather(scope.row.id)"
          >重新采集</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="step"
      :total="total"
      v-model:current-page="page"
    >
    </el-pagination>
  </div>
  <el-dialog
    v-model="visible"
    title="网站采集"
    :before-close="handleClose"
    width="50%"
  >
    <el-form :model="form">
      <el-form-item label="网站地址链接" :label-width="formLabelWidth">
        <el-input
          :rows="5"
          v-model="form.query_url"
          autocomplete="off"
          placeholder="输入采集网站详细地址，多条请换行或逗号隔开处理,最多可输入十组采集网址！"
          style="min-height: 100px;height:100px"
          type="textarea"

        />
<!--        <div class="tags" style="margin-top: 3%">-->
<!--          <el-tag-->
<!--            v-for="(tag, index) in tags"-->
<!--            :key="index"-->
<!--            closable-->
<!--            @close="removeTag(index)"-->
<!--            effect="dark"-->
<!--            class="long-tag"-->
<!--            >{{ tag }}</el-tag-->
<!--          >-->
<!--        </div>-->
      </el-form-item>

      <el-form-item :label-width="formLabelWidth">
        目前支持采集的域名：
        <br>
        <el-tag @click="openDomain(domainItem)" type="info" class="tag_el" effect="dark" v-for="domainItem in queryDomainList">
          <span style="cursor: pointer;">{{domainItem}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="conform" :loading="subLoading"> {{submitStr}} </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogVisible" title="简化文章" width="500px" :modal="false">
    <p v-html="msg"></p>
  </el-dialog>
</template>
<script>
import { reactive, toRefs, watch, onMounted } from "vue";
import { $confirm, $msg_s, $msg_e, $toUrl } from "../../utils/msg";
// 导入角色的API方法
import { webList, del, transFrom, again } from "../../api/webList";
import { useRouter } from "vue-router";
export default {
  name: "list",
  components: {},
  setup() {
    //定义数据
    let data = reactive({
      queryDomainList : [
          'www.hudson.org',
          'www.hrw.org',
          'www.rand.org',
          'www.atlanticcouncil.org',
          'www.wilsoncenter.org',
          'jamestown.org',
          'www.fordfoundation.org',
          'www.rfi.fr',
          'www.voachinese.com',
          'apnews.com'
      ],
      //表格数据
      tableData: [],
      //当前页码
      page: 1,
      //每页数量
      step: 20,
      //搜索内容
      search: "",
      //总数量
      total: 0,
      loading: true,
      visible: false,
      dialogVisible: false,
      formLabelWidth: "100px",
      form: {
        query_url: "",
      },
      msg: "",
      tags: [],
      subLoading:false,
      submitStr:"提交采集"
    });
    // 在setup函数内部
    const router = useRouter();
    onMounted(() => {
      //执行加载表格数据的方法
      loadTable();
    });
    //加载表格数据的方法
    let loadTable = async () => {
      data.loading = true;
      //查询条件
      let params = {
        page: data.page,
        step: data.step,
        search: data.search,
      };
      //获取查询结果
      let r = await webList(params);
      console.log(r);
      data.total = r.data.count;
      data.tableData = r.data.data;
      data.loading = false;
    };
    // 监听当前页码是否发生变化
    watch(
      () => data.page,
      () => {
        //重新加载表格数据
        loadTable();
      }
    );
    //删除方法
    let handleDelete = async (id) => {
      const res = await $confirm("确定要删除吗？");
      console.log(res);
      if (res) {
        console.log(id);
        let params = {
          ids: id,
        };
        let r = await del(params);
        console.log(r);
        if (r.code == 200) {
          $msg_s(r.message);
        } else {
          $msg_e(r.message);
        }
        loadTable();
      }
    };
    const handleClose = () => {
      $confirm("你确定要关闭弹出框吗？")
        .then(() => {
          data.form.query_url = "";
          data.visible = false;
        })
        .catch(() => {
          // catch error
        });
    };
    //提交网址采集
    let conform = async () => {

        data.subLoading = true;
        data.submitStr = '正在采集中';
        let params = {
          query_url: data.form.query_url
        };
        const res = await transFrom(params);
      data.subLoading = false;
      data.submitStr = '提交采集';
      $msg_s(res.message);
      if (res.code == 200) {
          data.form.query_url = "";
          data.visible = false;
          //重新加载表格数据
          await loadTable();
        }
    };
    let cancel = () => {
      data.form.query_url = "";
      data.visible = false;
      data.tags = [];
    };
    let handleMouseEnter = (row) => {
      data.msg = row.change_content;
      if (data.dialogVisible == false) {
        data.dialogVisible = true;
      }
    };
    //去详情页
    let toEetails = (id) => {
      console.log(id);
      router.push("/webView/gather?id=" + id);
    };
    //重新采集页面
    let againGather = async (id) => {
      data.loading = true;
      let params = {
        id: id,
      };
      let res = await again(params);
      if (res.code == 200) {
        $msg_s(res.message);
        //重新加载表格数据
        loadTable();
      } else {
        $msg_e(res.message);
        //重新加载表格数据
        loadTable();
      }
    };
    //输入网址键盘事件
    let isEnter = (event) => {
      console.log(event.keyCode);
      if (event.keyCode == 13) {
        //执行加载表格数据的方法
        loadTable();
      }
    };
    let addTag = () => {
      data.form.query_url = data.form.query_url.trim();
      if (data.form.query_url !== "") {
        if (data.tags.length <= 10) {
          data.tags.push(data.form.query_url);
          console.log(data.tags);
          data.form.query_url = "";
        } else {
          $msg_e("最多十个网址！");
          data.form.query_url = "";
        }
      }
    };
    let removeTag = (index) => {
      data.tags.splice(index, 1);
    };

    let openDomain = (domain) => {
      window.open('https://'+domain);
    }
    return {
      ...toRefs(data),
      handleDelete,
      loadTable,
      handleClose,
      conform,
      cancel,
      handleMouseEnter,
      toEetails,
      againGather,
      isEnter,
      addTag,
      removeTag,
      $toUrl,
      openDomain
    };
  },
};
</script>
<style scoped lang="scss">
.search{
	margin-top: 1%;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar-uploader-icon svg {
  margin-top: 74px; /* (178px - 28px) / 2 - 1px */
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
.tags {
  margin-top: 10px;
}
.long-tag {
  max-width: 80%;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  margin-left: 1%;
  position: relative;
}
::v-deep.el-tag .el-icon {
  position: absolute !important;
  top: 8px;
}
.url_p {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
  .el-textarea__inner{
    min-height: 100px;
    height: 100px;
  }
}
.tag_el{
  margin-left: 2%;
}

</style>
