<template>
  <div class="container">
    <div
        class="left"
        :style="{
        width: isCollapse ? '65px' : '200px',
        background: currentThemeColor,
      }"
    >
      <div class="logo">
        <div>{{ !isCollapse ? "网页收集系统" : "Web" }}</div>
      </div>
      <el-menu
          active-text-color="#ffd04b"
          :background-color="currentThemeColor"
          class="el-menu-vertical-demo"
          default-active="1-1"
          text-color="#fff"
          :collapse="isCollapse"
          :default-openeds="['1']"
          router
      >
        <el-menu-item index="/">网页手动采集</el-menu-item>

<!--        <el-sub-menu index="1">-->
<!--          <template #title>-->
<!--            <el-icon>-->
<!--              <aim />-->
<!--            </el-icon>-->
<!--            <span>网页采集</span>-->
<!--          </template>-->

<!--          <el-menu-item index="/">网页采集列表</el-menu-item>-->
<!--          <el-menu-item index="/configList">网页采集配置</el-menu-item>-->

<!--        </el-sub-menu>-->

    <el-menu-item index="/aiQuery">智能采集(新！)</el-menu-item>
        <!-- <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <add-location />
            </el-icon>
            <span>客房管理</span>
          </template>
          <el-menu-item index="/layout/roomType">类型管理</el-menu-item>
          <el-menu-item index="/layout/room">客房管理</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <briefcase />
            </el-icon>
            <span>顾客管理</span>
          </template>
          <el-menu-item index="/layout/guest">顾客管理</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4">
          <template #title>
            <el-icon>
              <cpu />
            </el-icon>
            <span>权限管理</span>
          </template>
          <el-menu-item index="4-1">权限管理</el-menu-item>
        </el-sub-menu> -->
      </el-menu>
    </div>
    <div class="right">
      <div class="top" :style="{ background: currentThemeColor }">
        <div class="icon" @click="isCollapse = !isCollapse">
          <el-icon size="20" v-show="!isCollapse">
            <fold />
          </el-icon>
          <el-icon size="20" v-show="isCollapse">
            <expand />
          </el-icon>
        </div>
        <div class="memu">
          <el-menu
              default-active="1"
              class="el-menu-demo"
              mode="horizontal"
              :background-color="currentThemeColor"
              text-color="#fff"
              active-text-color="#ffd04b"
          >
            <el-menu-item index="1" @click="$router.push('/')">
              <el-icon>
                <home-filled />
              </el-icon>
              首页
            </el-menu-item>
            <!-- <el-menu-item index="2" @click="$router.push('/layout/email')">
              <el-icon>
                <message />
              </el-icon>
              邮件
            </el-menu-item>
            <el-menu-item index="3" @click="$router.push('/layout/message')">
              <el-icon>
                <chat-dot-square />
              </el-icon>
              消息
            </el-menu-item> -->
             <el-sub-menu index="4">
              <template #title>
                <el-icon>
                  <baseball />
                </el-icon>
                切换皮肤
              </template>
              <el-menu-item
                @click="changeTheme(index)"
                :index="'4-' + (index + 1)"
                v-for="(item, index) in getColorNames"
                :key="index"
                >{{ item }}</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="5">
              <template #title>
                <el-icon>
                  <avatar />
                </el-icon>
                {{ $store.state.admin.admin.name }}
              </template>
<!--              <el-menu-item index="5-1" @click="$router.push('/layout/mine')"-->
<!--                >个人中心</el-menu-item-->
<!--              >-->
              <el-menu-item
                index="5-2"
                @click="$router.push('/user/resetPwd')"
                >修改密码</el-menu-item>
              <el-menu-item index="5-3" @click="exit">退出系统</el-menu-item>

            </el-sub-menu>
          </el-menu>
        </div>
      </div>
      <div class="bottom">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
// 导入组合式API
import { reactive, toRefs, computed ,onBeforeMount} from "vue";
// 导入全局状态管理对象
import { useStore } from "vuex";
// 导入路由器对象
import { useRouter } from "vue-router";
// 导入图标
import {
  Aim,
  // AddLocation,
  // Briefcase,
  // Cpu,
  Fold,
  Expand,
  Baseball,
  // ChatDotSquare,
  HomeFilled,
  // Message,
  Avatar,
} from "@element-plus/icons";
import {ElMessage} from "element-plus";
//导入API方法
// import {getOne} from '../api/admin'
export default {
  name: "Layout",
  components: {
    Aim,
    // AddLocation,
    // Briefcase,
    // Cpu,
    Fold,
    Expand,
    Baseball,
    // ChatDotSquare,
    HomeFilled,
    // Message,
    Avatar,
  },
  setup() {
    // 返回一个全局状态管理对象
    let $store = useStore();
    $store.state.admin.admin.name = '超级管理员';
    // 返回一个路由器对象
    let $router = useRouter();

    // 获取浏览器缓存中的颜色主题信息
    if (localStorage.getItem("color") && localStorage.getItem("colorName")) {
      let color = {
        color: localStorage.getItem("color"),
        name: localStorage.getItem("colorName"),
      };
      $store.dispatch("theme/updateCurrentThemeColor", color);
    }

    //定义数据
    let data = reactive({
      //左侧菜单是否折叠
      isCollapse: false,
    });

    // 从全局状态里面获取颜色数组
    let getColorNames = computed(() => {
      return $store.getters["theme/getColorNames"];
    });

    // 从全局状态里面获取当前主题色
    let currentThemeColor = computed(() => {
      return $store.state.theme.currentThemeColor.color;
    });

    // 切换主题的方法
    let changeTheme = (index) => {
      //根据index，获取对应的颜色主题
      let color = $store.state.theme.colors[index];
      //将获取到的颜色主题，保存到浏览器的缓存中
      localStorage.setItem("color", color.color);
      localStorage.setItem("colorName", color.name);
      //然后将该颜色主题，赋值给当前颜色主题
      $store.dispatch("theme/updateCurrentThemeColor", color);
    };

    // 退出系统方法
    let exit = () => {
      //清除所有的浏览器缓存
      ElMessage({
        message: '退出成功，即将返回登录页面......',
        type: 'success',
      })
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear();
        //跳转到登录页
        $router.push("/login");
      }, 1000)
    };

    // 页面挂载之前
    onBeforeMount(async ()=>{
      let token = localStorage.getItem('token')
      // 判断有无token，有token，才表示登录成功
      if(!token){
        //跳转到登录页
        $router.push('/login')
      }
      // $router.push('/')
      //获取登录账号
    })

    return {
      ...toRefs(data),
      getColorNames,
      currentThemeColor,
      changeTheme,
      exit,
    };
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  .left {
    transition: all 0.5s;
    .logo {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        color: white;
        font-size: 18px;
        border: 1px solid #eee;
        padding: 4px 10px;
        border-radius: 4px;
        // 不收缩
        flex-shrink: 0;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    .top {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        color: white;
        padding-left: 10px;
      }
      .memu {
        width: 650px;
      }
    }
    .bottom {
      flex: 1;
      padding: 6px;
    }
  }
}
</style>
