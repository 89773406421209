<template>
  <el-drawer v-model="drawer"
             title="智能采集配置面板"
             direction="rtl"
             size="80%"
             v-loading="configLoading"
             @close="cancelClick"
  >

    <el-space wrap>
      <el-card v-for="(item,index) in configFormList"   style="min-width: 430px;" shadow="always" >
        <template #header>
          <div class="card-header">
            <span>采集域名：<el-link  :href="'https://'+ item.domain" type="primary" target="_blank">{{item.domain}}</el-link></span>

            <div style="float: right;">
              <el-button  @click="toStopAiQuery(item.domain,index)" :loading="toStopAiQueryLoading[index]"  size="small" type="danger"  v-if="item.query_num > 0">停止采集</el-button>
              <el-button @click="toAiQuery(item.domain,index)"  :loading="toAiQueryLoading[index]"   size="small"  type="success" v-else> 立即采集</el-button>
            </div>
          </div>
        </template>

        <el-form  label-width="auto" style="max-width: 430px">
          <el-form-item label="定时采集开关：">
            <el-switch
                v-model="item.query"
                class="mb-2"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                active-text="开启"
                inactive-text="关闭"
                @change="saveConfig()"
            />
            <p class="mx-1" style="color: #909399">开启后会每12小时会根据规则自动采集一次</p>

          </el-form-item>

          <el-form-item label="采集时间筛选：">
            <el-radio-group @change="saveConfig()" v-model="item.time_where_type" size="large">
              <el-radio  value="1" label="1">当日</el-radio>
              <el-radio  value="7" label="7">一周内</el-radio>
              <el-radio  value="30" label="30">一月内</el-radio>
              <el-radio  value="-1" label="-1">自定义</el-radio>
            </el-radio-group>

            <el-date-picker
                v-if="'-1' === item.time_where_type"
                v-model="item.time_where_show"
                @change="saveConfig()"
                type="date"
                :clearable="false"
                :shortcuts="shortcuts"
                placeholder="请选择采集发布时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />

            <p class="mx-1" style="color: #909399">只采集对应文章发布/编辑时间段之后的信息</p>
          </el-form-item>

          <el-form-item label="采集任务剩余数：">
            {{item.query_num}}
            <el-button size="small" style="margin-left: 10%" type="warning" text bg v-if="item.query_num > 0"  loading>采集任务中</el-button>
            <el-button size="small" v-if="item.query_num > 0" @click="loadConfig">刷新</el-button>

          </el-form-item>

          <el-form-item label="上次采集时间：">
            {{item.last_query_time == 0 ? '暂无' : item.last_query_time}}
          </el-form-item>

        </el-form>

      </el-card>
    </el-space>

  </el-drawer>
</template>

<script >
import {ElMessage,ElMessageBox} from "element-plus";
import {getAiQueryConfig, saveConfig,aiQuery,stopAiQuery} from "@/api/webList";

export default {
  name: 'AiQueryConfig',
  data(){
    return {
      drawer: false,
      configFormList:[

      ],
      configLoading:false,
      value2:false,
      value1:'',
      todayNow:new Date(),
      toAiQueryLoading:[],
      toStopAiQueryLoading:[],
      checkInterval: null,
      shortcuts: [
        {
          text: '今日',
          value: new Date(),
        },
        {
          text: '昨日',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            return date
          },
        },
        {
          text: '一周',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            return date
          },
        },
      ]
    };
  },
    mounted() {

    },
    beforeDestroy() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval); // 清除定时器
      }
    },
    watch:{
      drawer(value){
        if(value){
          this.loadConfig();
          //5秒轮询
          this.timerCheck()
        }else{
          clearInterval(this.checkInterval); // 清除定时器
        }
      }
  },
  methods:{
    //加载配置项
    async loadConfig(){
      this.configLoading=true;
      let res = await getAiQueryConfig();
      this.configFormList = res.data;

      this.toAiQueryLoading = Array(this.configFormList.length).fill(false);
      this.toStopAiQueryLoading = Array(this.configFormList.length).fill(false);
      this.configLoading=false;
    },
    //保存配置项
    async saveConfig(){
      this.configLoading=true;
      this.configFormList.forEach(function (item){
        item.time_where = (new Date(item.time_where_show).getTime()) / 1000;
      });
      console.log(this.configFormList);
      var params={
        name:'ai_query',
        config:this.configFormList
      }
      let res = await saveConfig(params);
      if(res.code === 200){
        ElMessage({
          message: res.message,
          type: 'success',
        })
      }else{
        ElMessage({
          message: res.message,
          type: 'warning',
        })
      }

      this.configLoading=false;
    },
    //打开弹窗
    open(){
      this.drawer = true
    },
    //关闭弹窗
    cancelClick(){
      this.drawer = false;
    },
    timerCheck(){
      this.checkInterval = setInterval(this.loadConfig, 5000); // 每5秒检查一次
    },
    //执行域名智能采集
    async toAiQuery(domain_name,index){
      await ElMessageBox.confirm(
          '确定立即执行【' + domain_name +'】域名的采集?',
          '采集提醒',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(async () => {
        this.toAiQueryLoading[index]=true;
        var params={
          domain:domain_name
        }
        let res = await aiQuery(params);
        if(res.code === 200){
          //重新调用
          this.toAiQueryLoading[index]=false;
          await this.loadConfig();
          ElMessage({
            message: res.message,
            type: 'success',
          })
        }else{
          this.toAiQueryLoading[index]=false;
        }

      }).catch((res) => {
        this.toAiQueryLoading[index]=false;
      })
    },

    //停止域名智能采集
    toStopAiQuery(domain_name,index){
      ElMessageBox.confirm(
          '确认停止【' + domain_name +'】域名的采集?',
          '停止提醒',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(async () => {
        this.toStopAiQueryLoading[index]=true;
        var params={
          domain:domain_name
        }
        let res = await stopAiQuery(params);
        if(res.code === 200){
          //重新调用
          this.toStopAiQueryLoading[index]=false;
          await this.loadConfig();

          ElMessage({
            message: res.message,
            type: 'success',
          })
        }
      }).catch((res) => {
        this.toStopAiQueryLoading[index]=false;

      })
    }
  },
};

</script>
<style  >
.el-drawer__body{
  overflow-y: scroll;
}
</style>